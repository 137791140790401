<template>
    <div class="populnav darkBack">
        <div style="padding-left:0.1rem">游步道受欢迎度排名</div>
        <div class="divider"></div>
        <ul class="populcon">
            <li v-for="(item, index) in list.number" :key="index" @click="changeLine(item.id)">
                <div class="list">
                    <div style="margin-right:0.1rem;" :class="[index == 0 ? 'first' : index == 1 ? 'second' : index == 2 ? 'third' : '']">
                        {{index+1}}
                    </div>
                    <div>{{item.title}}</div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import {getAjax, timestamp} from "../../../assets/js/websocket";
import lotteryjson from '/public/data/lottery.json';
import bus from "../../../assets/js/bus";
export default {
    created() {
        var that = this
        let info = JSON.parse(window.localStorage.getItem("screenUserInfo"));
        let url;
        if(info.is_city == 1) url = "/map/screen/getFootPath";
        else url = "/map/screen/getFootPath?area_id="+info.area_id;
        var data = {
            token: window.getToken(),
            app_id: window.appId,
            time: timestamp(),
        }
        getAjax({
            url: url,
            method:"GET",
            data:data,
            success:function(res){
                // console.log(res)
                if(res.result){
                    that.list.number = res.data;
                }
            },
        })

    },
    data () {
        return {
            // list:window.setData('游步道-游步道受欢迎度排名', {
            //     number:["兰亭游步道","陶宴岭游步道","日铸岭游步道","西天竺游步道","大雾尖游步道","越王峥游步道","月华山游步道（探险步道）","宛委山步道区","樱花林步道","石轿步道","香宛步道","马秦步道"]
            // }),
            list:{
                number:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
            },
        }
    },
    mounted() {
        
    },
    destroyed () {
        bus.$off('talkId')  
    },
    methods: {
        changeLine(id){
            let that = this;
            // console.log(id)
            var data = {
                token: window.getToken(),
                app_id: window.appId,
                time: timestamp(),
            }
            getAjax({
                url: "/map/screen/getFootPath?id="+id,
                method:"GET",
                data:data,
                success:function(res){
                    if(res.result){
                        lotteryjson.taohuadao.data.center = [res.data[0].lng, res.data[0].lat]
                        lotteryjson.taohuadao.data.points = JSON.parse(res.data[0].lines)
                        // console.log(lotteryjson.taohuadao)
                        // _this.plug.upDataMap(lotteryjson.taohuadao)
                        that.plug.upDataMap(lotteryjson.taohuadao)
                    }else{
                        that.plug.upDataMap(lotteryjson.taohuadao)
                    }
                },
            })
            bus.$emit('talkId',id)
        },
    }
}
</script>

<style scoped>
.populnav{
    width:3.20rem;
    height:6.2rem;
    font-size: 0.20rem;
    color: #FFFFFF;
    padding: 0.2rem;
    position: relative;
}
.populcon{
    width:2.6rem;
    height:6.06rem;
    margin: auto;
    margin-top: 0.2rem;
    color:#7EA7E2;
    font-size: 0.14rem;
    overflow: hidden;
}
.populcon>li{
    line-height: 0.26rem;
}
.list{
    display: flex;
    align-items: center;
    text-align: center;
}
.list > div:first-child{
    width: 0.14rem;
}
.first{
    width: 0.14rem;
    height: 0.14rem;
    line-height: 0.14rem;
    text-align: center;
    border-radius: 50%;
    color: white;
    background-image: linear-gradient(to bottom , #FB8B2E ,#FEC554);
}
.second{
    width: 0.14rem;
    height: 0.14rem;
    line-height: 0.14rem;
    text-align: center;
    border-radius: 50%;
    color: white;
    background-image: linear-gradient(to bottom , #FD6B87 ,#FF8FA5);
}
.third{
    width: 0.14rem;
    height: 0.14rem;
    line-height: 0.14rem;
    text-align: center;
    border-radius: 50%;
    color: white;
    background-image: linear-gradient(to bottom , #0245D5 ,#00AEEB);
}

.divider{
    width: 2rem;
    height: 0.02rem;
    background-image: linear-gradient(to right,#0F1626,#7EA7E2, #131A2A );
    margin-top: 0.2rem;
    margin-left: 0.1;
}
</style>